import React from 'react';
import Modal from 'react-modal';
import { StripeCallInternational } from "./stripe";
import { Base64 } from 'js-base64';
import ImageZoom from 'react-medium-image-zoom';
import './modal.css';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex: '100'
  }
};

class ModalThing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: props.open,
      size: '',
      email: '',
      address: ''
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.formatQuery = this.formatQuery.bind(this);
    this.encodeBase64 = this.encodeBase64.bind(this);

    this.myRef = React.createRef();
  }

  openModal() {
    this.props.openModal()
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.props.closeModal()
  }

  encodeBase64(s){
    return Base64.encodeURI(s);
  };

  formatQuery() {
    let query = `?size=${this.encodeBase64(this.state.size)}&email=${this.encodeBase64(this.state.email)}&address=${this.encodeBase64(this.state.address)}`;
    // alert(query)
    return query;
  }

  render() {
    return (
      <div className={'balkans-modal'} id={'#balkans-modal'}>
        <Modal
          isOpen={this.props.open}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ref={this.myRef}
          ariaHideApp={false}
        >
          {
            this.state.size === '' &&
            <>
              <div
                className={'img-wrapper'}
              >
                <ImageZoom
                  image={{
                    src: this.props.shirt,
                    alt: 'Balkans shirt',
                    className: 'img',
                    style: { width: '100%' }
                  }}
                  zoomImage={{
                    src: this.props.shirt,
                    alt: 'Balkans shirt'
                  }}
                />
              </div>
              <h2>What size do you want?</h2>
              <div className={'form'}>
                <div className={'form-btn-item'}>
                  <button
                    disabled={this.props.sizes.small < 1 }
                    onClick={() => this.setState({ size: 'small' })}
                  >small</button>
                  {
                    this.props.sizes.small < 1 ?
                      <span>none left</span> : <span>only {this.props.sizes.small} left</span>
                  }
                </div>
                <div className={'form-btn-item'}>
                  <button
                    disabled={this.props.sizes.medium < 1 }
                    onClick={() => this.setState({ size: 'medium' })}
                  >medium</button>
                  {
                    this.props.sizes.medium < 1 ?
                      <span>none left</span> : <span>only {this.props.sizes.medium} left</span>
                  }
                </div>
                <div className={'form-btn-item'}>
                  <button
                    disabled={this.props.sizes.large < 1 }
                    onClick={() => this.setState({ size: 'large' })}
                  >large</button>
                  {
                    this.props.sizes.large < 1 ?
                      <span>none left</span> : <span>only {this.props.sizes.large} left</span>
                  }
                </div>
                <div className={'form-btn-item'}>
                  <button
                    disabled={this.props.sizes.xlarge < 1 }
                    onClick={() => this.setState({ size: 'xlarge' })}
                  >extra large</button>
                  {
                    this.props.sizes.xlarge < 1 ?
                      <span>none left</span> : <span>only {this.props.sizes.xlarge} left</span>
                  }
                </div>
              </div>
            </>
          }
          {
            this.state.size !== '' &&
            <>
              <form>
                <h2>What's your email?</h2>
                <input
                  value={this.state.email}
                  onChange={e => this.setState({email: e.target.value})}
                  type={'email'}
                  onFocus={ () => this.props.focus() }
                  onBlur={ () => this.props.blur() }
                />
                <h2 className={'shipping'}>Where do we ship it?</h2>
                <span>For shipping outside the USA please email <a href={'mailto:balkansbalkans@gmail.com'}>balkansbalkans@gmail.com</a></span>
                <textarea
                  rows="4"
                  cols="50"
                  value={this.state.address}
                  onChange={e => this.setState({address: e.target.value})}
                  onFocus={ () => this.props.focus() }
                  onBlur={ () => this.props.blur() }
                />
              </form>
              <button onClick={() => this.setState({ size: '' })}>back</button>
              <button disabled={ this.state.address === '' || this.state.email === '' } style={{ float: 'right'}} onClick={() => StripeCallInternational( this.formatQuery() )}>pay for it</button>
            </>
          }

        </Modal>
      </div>
    );
  }
}

export default ModalThing
