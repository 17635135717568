import React, { Component } from "react";
import * as THREE from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import styled from 'styled-components';
import "./tshirt.css"

const Wrapper = styled.div`
  width: 500px;
  height: 600px;
  // margin: auto;
`;

const OrbitControls = require("three-orbit-controls")(THREE);

class Tshirt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      mouseX: 0,
      mouseY: 0
    };
    this.animate = this.animate.bind(this);
    this.initializeCamera = this.initializeCamera.bind(this);
    this.handleMove = this.handleMove.bind(this);
  }

  componentDidMount() {
    this.THREE = THREE;

    this.clock = new this.THREE.Clock();
    this.width = this.mount.getBoundingClientRect().width;
    this.height = this.mount.getBoundingClientRect().height;
    this.scene = new this.THREE.Scene();
    this.camera = new this.THREE.PerspectiveCamera(75, this.width / this.height, 0.1, 1000);
    this.light = new this.THREE.DirectionalLight( 0x1d79d9, 1 );
    this.light2 = new this.THREE.DirectionalLight( 0x1d79d9, 1 );
    this.light2.position.set( 2, 2, 2 ).normalize();
    this.light.position.set( 1, 1, 1 ).normalize();
    this.scene.add( this.light2 );
    this.scene.add( this.light );
    this.ambient = new this.THREE.AmbientLight( 0x8bc34a, 1 );
    this.scene.add( this.ambient );
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setClearColor( 0x000000, 0 ); // the default

    this.speed = 8; // higher means slower
    this.count = 0;

    this.renderer.gammaOutput = true;
    this.renderer.gammaFactor = 2.2;
    this.renderer.physicallyCorrectLights = true;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;
    this.renderer.setSize(this.width, this.height);
    this.mount.appendChild(this.renderer.domElement);
    this.initializeCamera();

    const sphere = new this.THREE.SphereBufferGeometry( 1.5, 16, 8 );
    this.light1 = new this.THREE.PointLight( 0x22A0D6, 20, 100 );
    this.light1.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6} ) ) );
    this.scene.add( this.light1 );
    this.light2 = new this.THREE.PointLight( 0x22A0D6, 20, 100 );
    this.light2.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light2 );
    this.light3 = new this.THREE.PointLight( 0x22A0D6, 20, 100 );
    this.light3.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light3 );
    this.light4 = new this.THREE.PointLight( 0x22A0D6, 20, 100 );
    this.light4.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light4 );


    this.headGeometry = new this.THREE.BoxGeometry( 20, 20, 20 );
    // this.headMaterial = new this.THREE.MeshBasicMaterial( {color: 0xffff00} );
    new this.THREE.TextureLoader().load(
      this.props.head,
      function ( texture ) {
        this.headMaterial = new THREE.MeshPhongMaterial({
          map: texture,
          shininess: 1
        });
        this.head = new THREE.Mesh( this.headGeometry, this.headMaterial );
        this.head.position.y = 40;
        this.head.position.z = 0;
        this.head.position.x= 0;
        this.scene.add( this.head );
      }.bind(this)
    );


    this.shirt = null;
    this.mixer = null;
    const loader = new OBJLoader();
    loader.load( 'https://balkans.s3.amazonaws.com/shirt.obj' , ( obj ) => {
      this.shirt = obj;
      new this.THREE.TextureLoader().load(
        this.props.shirt,
        function ( texture ) {
          this.shirtMaterial = new THREE.MeshPhongMaterial({
            map: texture,
            shininess: 100
          });
          this.shirt.children[0].material = this.shirtMaterial;
          this.shirt.scale.multiplyScalar( 125 );
          this.shirt.position.y = -40;
          this.shirt.position.z = 0;
          this.shirt.position.x= 0;
          this.scene.add( this.shirt );
          // console.log(this.scene)
          this.shirtLight = new this.THREE.PointLight( 0xff0000, 0, 0 );
          this.shirtLight.position.set( 30, -20, 30 );
          this.scene.add( this.shirtLight );

          this.animate();

          this.props.loaded();
        }.bind(this),
      );
    } );
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.frameId);
    this.mount.removeChild(this.renderer.domElement);
  }

  initializeCamera() {
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 200;
  }

  animate() {
    this.frameId = window.requestAnimationFrame(this.animate);
    const delta = this.clock.getDelta();
    if (this.mixer !== null ) {
      this.mixer.update(delta);
    }
    this.renderer.render(this.scene, this.camera);

    if (this.shirt !== null) {
      this.shirt.rotateY(.01);
    }

    if (this.head !== null) {
      this.head.rotateY(-.01);
    }

    if (this.camera !== null && this.camera.position.z > 80) {
      this.camera.position.z -= 3;
    }

    this.count += .01;
    this.light1.position.x = Math.sin( this.count * 0.7 ) * 30;
    this.light1.position.y = Math.cos( this.count * 0.5 ) * 40;
    this.light1.position.z = Math.cos( this.count * 0.3 ) * 30;
    this.light2.position.x = Math.cos( this.count * 0.3 ) * 30;
    this.light2.position.y = Math.sin( this.count * 0.5 ) * 40;
    this.light2.position.z = Math.sin( this.count * 0.7 ) * 30;
    this.light3.position.x = Math.sin( this.count * 0.7 ) * 30;
    this.light3.position.y = Math.cos( this.count * 0.3 ) * 40;
    this.light3.position.z = Math.sin( this.count * 0.5 ) * 30;
    this.light4.position.x = Math.sin( this.count * 0.3 ) * 30;
    this.light4.position.y = Math.cos( this.count * 0.7 ) * 40;
    this.light4.position.z = Math.sin( this.count * 0.5 ) * 30;

    // if (this.shirt !== null) {
    //   const mouse2D = new this.THREE.Vector2(
    //     (this.state.mouseX / ( this.props.width )) * 2 - 1,
    //     -(this.state.mouseY / this.props.height ) * 2 + 1
    //   );
    //   const raycaster = new this.THREE.Raycaster();
    //   raycaster.setFromCamera(mouse2D, this.camera);
    //   let intersects = raycaster.intersectObjects(this.shirt.children);
    //   if (intersects.length === 0) {
    //     // if (this.state.hover === true) {
    //     //   this.scene.children[7].intensity = 0;
    //     // }
    //     this.setState({ hover: false })
    //   }
    //   if (intersects.length > 0 && this.state.hover === false ) {
    //     // for ( let i = 0; i < intersects.length; i++ ) {
    //     //   this.scene.children[7].intensity = 10000;
    //     // }
    //     this.setState({ hover: true })
    //   }
    // }
  }

  handleMove(event) {
    this.setState({
      mouseX: event.clientX,
      mouseY: event.clientY
    })
  }

  render() {
    return (
      <>
        {/*<div*/}
        {/*  className={'tshirt-text'}*/}
        {/*  style={{*/}
        {/*    opacity: this.state.hover && !this.props.open ? 1 : 0,*/}
        {/*    top: `${this.state.mouseY - 50}px`,*/}
        {/*    left: `${this.state.mouseX}px`*/}
        {/*  }}*/}
        {/*>Click me to buy me</div>*/}
        <Wrapper
          onMouseLeave={ () => this.setState({ hover: false})}
          onMouseEnter={ () => this.setState({ hover: true})}
          onMouseMove={this.handleMove}
          id="canvas"
          ref={mount => {
            this.mount = mount;
          }}
        />
      </>
    );
  }

}
export default Tshirt;
