const StripeCall = (query) => {
  if (typeof window !== 'undefined' && window) {
    const stripe = window.Stripe('pk_live_lrtf5DqeGt6VKGmMow26c9b100T4rCUXed');
    let url = window.location.href;
    const re = /^(?:http:\/\/|www\.|https:\/\/)([^\/]+)/;
    url = re.exec(url)[0];
    stripe.redirectToCheckout({
      items: [{sku: 'sku_G4jG561NPZvNM1', quantity: 1}],
      successUrl: url + '/success/' + query,
      cancelUrl: url + '/' + query,
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
  }
};


export const StripeCallInternational = (query) => {
  if (typeof window !== 'undefined' && window) {
    const stripe = window.Stripe('pk_live_lrtf5DqeGt6VKGmMow26c9b100T4rCUXed');
    let url = window.location.href;
    const re = /^(?:http:\/\/|www\.|https:\/\/)([^\/]+)/;
    url = re.exec(url)[0];
    stripe.redirectToCheckout({
      items: [{sku: 'sku_G7S6XwwYhlt0gN', quantity: 1}],
      successUrl: url + '/success/' + query,
      cancelUrl: url + '/' + query,
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
  }
};

// export const StripeCallInternational = (query) => {
//   if (typeof window !== 'undefined' && window) {
//     const stripe = window.Stripe('pk_test_8OZmWSDLeIRHY8HkvhTw1RR400oj3KRfhC');
//     let url = window.location.href;
//     const re = /^(?:http:\/\/|www\.|https:\/\/)([^\/]+)/;
//     url = re.exec(url)[0];
//     stripe.redirectToCheckout({
//       items: [{sku: 'sku_G7SNCaVjZ1rv4E', quantity: 1}],
//       successUrl: url + '/success/' + query,
//       cancelUrl: url + '/' + query,
//     }).then(function (result) {
//       // If `redirectToCheckout` fails due to a browser or network
//       // error, display the localized error message to your customer
//       // using `result.error.message`.
//     });
//   }
// };
// const StripeCall = (query) => {
//   if (typeof window !== 'undefined' && window) {
//     const stripe = window.Stripe('pk_test_8OZmWSDLeIRHY8HkvhTw1RR400oj3KRfhC');
//     let url = window.location.href;
//     const re = /^(?:http:\/\/|www\.|https:\/\/)([^\/]+)/;
//     url = re.exec(url)[0];
//     stripe.redirectToCheckout({
//       items: [{sku: 'sku_Fxjov48bSUtKV1', quantity: 1}],
//       successUrl: url + '/success/' + query,
//       cancelUrl: url + '/' + query,
//     }).then(function (result) {
//       // If `redirectToCheckout` fails due to a browser or network
//       // error, display the localized error message to your customer
//       // using `result.error.message`.
//     });
//   }
// }
export default StripeCall
