import React, {useEffect} from "react"
import { DistortionText } from 'react-text-fun';
import Layout from "../components/layout"
import Tshirt from "../components/tshirt"
import SEO from "../components/seo"
import {
  useWindowSize,
} from '@react-hook/window-size'
import ModalThing from './../components/modalInternational'
import './index.css'
import {graphql, useStaticQuery} from "gatsby";
import Cursor from "../components/cursor";
import { Base64 } from 'js-base64';
import { StripeCallInternational } from "./../components/stripe";


const International = () => {
  const [width, height] = useWindowSize(
    360 /* initialWidth when there is no window */,
    720 /* initialHeight when there is no window */,
    {wait: 100}
  );

  const data = useStaticQuery(graphql`
    query {
      threedImage: file(relativePath: { eq: "balkans_shirt.png" }) {
        childImageSharp {
          resize (width: 500) {
            src
          }
        }
      }
      tomHead: file(relativePath: { eq: "tom_head.jpg" }) {
        childImageSharp {
          resize (width: 300) {
            src
          }
        }
      }
      shirtImage: file(relativePath: { eq: "shirt2.JPG" }) {
        childImageSharp {
          resize (width: 600) {
            src
          }
        }
      }
    }
  `);

  const [sizes, setSizes] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  useEffect(() => {
    getInvitory();
    // return (
    //   alert('Are you sure you want to enter BALKANS?')
    // )
    let query = `?size=${Base64.encodeURI('balkans')}&email=${Base64.encodeURI('there')}&address=${Base64.encodeURI('ALREADY SHIPPED')}`;
    StripeCallInternational(query)
  }, []); // empty-array means don't watch for any updates

  const getInvitory = () => {
    fetch('/.netlify/functions/getShirts', {
      method: 'get',
      headers: new Headers({ "Content-Type": "application/json" })
    }).then( (response) => {
      return response.json();
    }).then( (data) => {
      setSizes(data[0].data)
    });
  };

  const OpenTabs = () => {
    if (typeof window !== 'undefined' && window) {
      for (let i = 0; i - 10 ; i++) {
        window.open('/', '_blank');
        // redirectWindow.location;
      }
    }
  };

  // if (!loaded) {
  //   return(
  //     <div className={'loader-wrapper'}>
  //       <Loader
  //         type="MutatingDots"
  //         color="#00BFFF"
  //         height={100}
  //         width={100}
  //         // timeout={3000} //3 secs
  //       />
  //     </div>
  //   )
  // }

  return (
    <Layout>
      <SEO title="Home" />
      <Cursor focused={focus} />
      <div className={ loaded ? 'loader-wrapper loaded' : "loader-wrapper"}>
        <p>Loading international...</p>
      </div>
      <div style={{ marginBottom: `1.45rem` }}>
        <ModalThing
          open={open}
          openModal = { () => setOpen(true)}
          closeModal = { () => setOpen(false)}
          shirt={data.shirtImage.childImageSharp.resize.src}
          sizes={sizes}
          focus={ ()=> setFocus(true) }
          blur={ ()=> setFocus(false) }
        />
        <div style={{ display: `flex`, justifyContent: `center` }} onClick={() => setOpen(true)}>
          <Tshirt
            shirt={data.threedImage.childImageSharp.resize.src}
            head={data.tomHead.childImageSharp.resize.src}
            open={open}
            width={width}
            height={height}
            loaded={ () => setLoaded(true) }
          />
        </div>
        <div className={'balkans-tabs'} style={{ display: `flex`, justifyContent: `center`, margin: '4rem 0' }} onClick={() => OpenTabs()}>
          <DistortionText
            text="Balkans tabs"
            fontFamily={'AppleChancery'}
            fill={'black'}
            fontSize={48}
            speed={.5}
            rotation={45.0}
            distortX={1.5}
            distortY={1.5}
            noiseAmplitude={0.5}
            noiseVolatility={.5}
          />
        </div>
      </div>
    </Layout>
  )
}

export default International
